import { Button, Card, Flex, Input, message, Spin } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CheckCircleTwoTone, LoadingOutlined } from "@ant-design/icons";

const API_BASE_URL =
  "https://server-10087364658.development.catalystappsail.com/api/api_hour";
let attendee_info = {
  name: undefined,
  email: undefined,
  phone: undefined,
};
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const numberRegex = /^[0-9]*$/;

const TicketActivation = () => {
  const location = useLocation();
  const [attendee, setAttendee] = useState([]);
  const payment_id = new URLSearchParams(location.search).get("payment_id");
  const customer_id = new URLSearchParams(location.search).get("customer_id");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetch_attendee_details() {
      setLoading(true);

      await axios
        .get(
          `${API_BASE_URL}/customer/send_attendee_details?payment_id=${payment_id}&customer_id=${customer_id}`
        )
        .then((response) => {
          console.log(response);

          if (response.data.success === false) {
            return message.error(response.data.message);
          }

          let array = [];
          for (let i = 0; i < response.data?.data?.no_of_tickets; i++) {
            array.push(attendee_info);
          }

          setAttendee(array);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    fetch_attendee_details();
  }, []);

  return (
    <>
      {loading ? (
        <Flex
          align="center"
          justify="center"
          style={{ backgroundColor: "#fff", minHeight: "100vh" }}
        >
          <LoadingOutlined spin style={{ color: "#000", fontSize: "2rem" }} />
        </Flex>
      ) : (
        <div className="ticket_activation">
          {attendee.length &&
            attendee.map((item, i) => {
              return (
                <CardComponent
                  item={item}
                  i={i}
                  key={i}
                  attendee={attendee}
                  setAttendee={setAttendee}
                  customer_id={customer_id}
                  payment_id={payment_id}
                />
              );
            })}
        </div>
      )}
    </>
  );
};

const CardComponent = ({
  item,
  i,
  attendee,
  setAttendee,
  customer_id,
  payment_id,
}) => {
  const [btnLoading, setLoading] = useState(false);
  const [send, setSend] = useState(false);

  const handleChange = (e, index) => {
    const { value, name } = e.target;

    let map = attendee.map((item, i) => {
      if (i === index) {
        if (name === "phone" && !numberRegex.test(value)) {
          return item;
        }

        return {
          ...item,
          [name]: value,
        };
      }

      return item;
    });

    setAttendee(map);
  };
  const activate = async (index) => {
    if (
      !attendee[index]?.name ||
      !attendee[index]?.email ||
      !emailRegex.test(attendee[index]?.email) ||
      !attendee[index]?.phone ||
      attendee[index]?.phone?.length !== 10
    ) {
      return;
    }

    setLoading(true);

    await axios
      .post(`${API_BASE_URL}/customer/activate_attendee`, {
        customer_id: customer_id,
        payment_id: payment_id,
        name: attendee[index]?.name,
        email: attendee[index]?.email,
        phone: attendee[index]?.phone,
      })
      .then((response) => {
        if (response.data.sucess === false) {
          return message.error(response.data?.message);
        }

        setSend(true);
      })
      .finally((err) => {
        setLoading(false);
      });
  };

  console.log(attendee);

  return (
    <Card
      title={`Attendee ${i + 1}`}
      style={{ width: "100%", maxWidth: "500px", margin: "auto" }}
      extra={
        <>
          {send === true ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <Button
              size="small"
              type="primary"
              onClick={() => activate(i)}
              loading={btnLoading}
              disabled={
                !attendee[i]?.name ||
                !attendee[i]?.email ||
                !emailRegex.test(attendee[i]?.email) ||
                !attendee[i]?.phone ||
                attendee[i]?.phone?.length !== 10
              }
            >
              Activate
            </Button>
          )}
        </>
      }
    >
      <Flex gap={24} vertical>
        <Flex gap={16} style={{ width: "100%" }}>
          <label htmlFor={`name ${i}`} style={{ width: "20%" }}>
            Name
          </label>

          <Input
            id={`name ${i}`}
            name="name"
            value={attendee[i]?.name}
            style={{ width: "100%" }}
            onChange={(e) => handleChange(e, i)}
            disabled={send === true}
            status={attendee[i]?.name === "" && "error"}
          />
        </Flex>
        <Flex gap={16} style={{ width: "100%" }}>
          <label htmlFor={`email ${i}`} style={{ width: "20%" }}>
            Email
          </label>

          <Input
            id={`email ${i}`}
            name="email"
            value={attendee[i]?.email}
            style={{ width: "100%" }}
            onChange={(e) => handleChange(e, i)}
            disabled={send === true}
            status={
              (attendee[i]?.email === "" ||
                (attendee[i]?.email && !emailRegex.test(attendee[i]?.email))) &&
              "error"
            }
          />
        </Flex>
        <Flex gap={16} style={{ width: "100%" }}>
          <label htmlFor={`phone ${i}`} style={{ width: "20%" }}>
            Phone
          </label>

          <Input
            id={`phone ${i}`}
            name="phone"
            value={attendee[i]?.phone}
            style={{ width: "100%" }}
            onChange={(e) => handleChange(e, i)}
            addonBefore={
              <img
                src="https://tse2.mm.bing.net/th?id=OIP.HHOKl0wx70n3CsqKlTR8JAHaEA&pid=Api&P=0&h=180"
                alt=""
                style={{ width: "20px" }}
              />
            }
            maxLength={10}
            disabled={send === true}
            status={
              (attendee[i]?.phone === "" ||
                (attendee[i]?.phone && attendee[i]?.phone.length !== 10)) &&
              "error"
            }
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default TicketActivation;
